import { UserType } from '../../models/user';

export const currentUserFragment = `
  fragment currentUserFragment on User {
    id
    firebaseId
    email
    organization {
      id
      name
      currentCharge
      projectedCharge
      billingInfo {
        id
        name
        lastFour
        expirationDate
      }
      pastCharges {
        id
        chargedOn
        amount
        cardNumber
      }
    }
  }
`;

export interface CurrentUserFragmentType extends UserType {}
