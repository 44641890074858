import cx from 'classnames';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';

import { useResponsiveLayout } from '../../../helpers/dom';

import MobileInvocationsTable from './MobileInvocationsTable';
import InvocationStatus from '../InvocationStatus';

import { InvocationType } from '../../../models/invocation';

const CELL_CNAME = 'px-4 py-3';
const HEADER_CNAME = `${CELL_CNAME} text-left text-sm text-gray-700`;

// TODO: pagination
// TODO: Add feather icons
// TODO: Text overflow ellipsis for payload

interface InvocationsTableProps {
  invocations: InvocationType[];
  emptyState: JSX.Element;
}

const InvocationsTable = ({
  invocations,
  emptyState,
}: InvocationsTableProps) => {
  const { isMobile, isTablet } = useResponsiveLayout();


  if (invocations.length === 0) return emptyState;

  if (isMobile) return <MobileInvocationsTable invocations={invocations} />;

  const templateColumnsStyle = isTablet
    ? '48px 120px 120px 1fr'
    : '48px 120px 120px 1fr 2fr';

  return (
    <div className='pt-3'>
      <div className='grid' style={{ gridTemplateColumns: templateColumnsStyle }}>
        <div />
        <div className={HEADER_CNAME}>Invoked At</div>
        <div className={HEADER_CNAME}>Type</div>
        <div className={HEADER_CNAME}>Identifier</div>
        {!isTablet && (
          <div className={HEADER_CNAME}>Payload</div>
        )}
      </div>

      {invocations.map(invocation => {
        return (
          <Link
            key={invocation.id}
            to={`/invocations/${invocation.id}`}
            className='grid hover:bg-gray-100'
            style={{ gridTemplateColumns: templateColumnsStyle }}
          >
            <div className='pl-4 pr-2 py-3'>
              <InvocationStatus status={invocation.status} />
            </div>
            <div className={cx(CELL_CNAME, 'text-sm')} style={{ minWidth: 120 }}>
              {format(new Date(invocation.createdAt), 'MMM d, pp')}
            </div>
            <div className={CELL_CNAME}>{invocation.serviceType}</div>
            <div className={cx(CELL_CNAME, 'truncate')}>{invocation.foreignIdentifier}</div>
            {!isTablet && (
              <div className={cx(CELL_CNAME, 'truncate')}>{invocation.requestPayload}</div>
            )}
          </Link>
        );
      })}
    </div>
  );
};

export default InvocationsTable;
