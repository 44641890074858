import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

import { useSession } from '../../contexts/Session';
import useErrorToastHandling from '../../hooks/use-error-toast-handling';

import ChevronRight from '@heroicons/react/solid/ChevronRightIcon';
import Card from '../../components/atoms/Card';
import PageHeader from '../../components/atoms/PageHeader';
import PageSpinner from '../../components/atoms/PageSpinner';
import InvocationsTable from '../_partials/InvocationsTable';
import NoUsageEmptyState from '../_partials/NoUsageEmptyState';

import { invocationRowFragment, InvocationRowFragmentType } from '../../graphql/fragments/invocation-row';

const GET_DASHBOARD_INVOCATIONS = gql`
  ${invocationRowFragment}

  query GetDashboardInvocations($offset: Int!, $limit: Int!) {
    invocations(offset: $offset, limit: $limit) {
      ...invocationRowFragment
    }
  }
`;

interface GetDashboardInvocationsData {
  invocations: InvocationRowFragmentType[]
}
// TODO: type variables?

const Dashboard = () => {
  const { currentUser } = useSession();
  const { data, loading, error } = useQuery<GetDashboardInvocationsData>(GET_DASHBOARD_INVOCATIONS, {
    variables: { limit: 10, offset: 0 },
    skip: !currentUser?.organization
  });
  const { invocations = [] } = data || {};
  useErrorToastHandling(
    'We were unable to retrieve your invocations. We have been alerted and will find a fix soon.',
    error
  );

  return (
    <>
      <PageHeader className='mb-4' title='Dashboard' />

      {/*
      <div className='mb-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4'>
        <Card className='p-4 mr-3'>
          Cost so far this month
        </Card>

        <Card className='p-4 ml-1 mr-2'>
          Projected Cost
        </Card>

        <Card className='p-4 ml-2 mr-1'>
          Successes
        </Card>

        <Card className='p-4 ml-3'>
          Errors
        </Card>
      </div>
      */}

      <Card className='py-3'>
        <div className='px-4 pt-2 flex items-end'>
          <h3 className='pr-4 text-xl font-medium'>Recent Invocations</h3>

          <Link to='/invocations' className='text-blue-500 flex items-center grow'>
            See all
            <ChevronRight className='h-6' />
          </Link>
        </div>

        {loading ? (
          <PageSpinner />
        ) : (
          <InvocationsTable
            invocations={invocations}
            emptyState={<NoUsageEmptyState />}
          />
        )}
      </Card>
    </>
  );
}

export default Dashboard;
