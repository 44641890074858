import AccountHeader from './AccountHeader';
import Card from '../../components/atoms/Card';

function AccountEmptyState() {
  return (
    <>
      <AccountHeader />

      <Card className='p-4 flex justify-center items-center'>
        No Account information found. You either still need to create an Organization or
        there is an error. Please contact support to get this resolved.
      </Card>
    </>
  );
}

export default AccountEmptyState;
