import { Formik, Form } from 'formik';
import { useMutation, gql } from '@apollo/client';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import * as Sentry from "@sentry/browser";

import { useAuth } from '../Auth';
import { currentUserFragment, CurrentUserFragmentType } from '../../graphql/fragments/current-user';
import { MutationResponse } from '../../types/graphql';

import Modal, { ModalHeader } from '../../components/atoms/Modal';
import FormikTextField from '../../components/formik/FormikTextField';
import FormikGeneralErrors from '../../components/formik/FormikGeneralErrors';
import FormikButton from '../../components/formik/FormikButton';

const CREATE_USER_AND_ORGANIZATION = gql`
  ${currentUserFragment}

  mutation CreateUserAndOrganization($input: CreateUserAndOrganizationInput!) {
    createUserAndOrganization(data: $input) {
      code
      success
      message
      user {
        ...currentUserFragment
      }
    }
  }
`;

interface CreateUserAndOrganizationResponse extends MutationResponse {
  user: CurrentUserFragmentType;
}
interface MutationRes {
  createUserAndOrganization: CreateUserAndOrganizationResponse;
}
interface MutationVars {
  input: {
    email: string;
    firebaseId: string;
    organizationName: string;
  }
}

interface OnboardingModalProps {
  isOpen: boolean;
  onSuccess: () => void;
}

function OnboardingModal({ isOpen, onSuccess }: OnboardingModalProps) {
  const { authState } = useAuth();
  const { uid, email } = authState || {};

  const [createUserAndOrganization] = useMutation<MutationRes, MutationVars>(CREATE_USER_AND_ORGANIZATION);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
    >
      <ModalHeader title='Create your Organization' onClose={null} />

      <Formik
        initialValues={{ organizationName: '' }}
        validationSchema={yup.object().shape({
          organizationName: yup.string().required('Organization Name is required'),
        })}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          if (!email || !uid) throw new Error('Email or uid undefined when creating org');

          const input = {
            email,
            firebaseId: uid,
            organizationName: values.organizationName,
          };

          createUserAndOrganization({ variables: { input } }).then((res) => {
            toast.success(`Success! Organization "${values.organizationName}" has been created`);
            onSuccess();
          }).catch((e) => {
            setSubmitting(false);
            setFieldError('general', 'An error occurred - our team is looking into it!');
            Sentry.captureException(e);
          });
        }}
      >
        <Form>
          <div className='mb-6 text-sm'>
            All API requests are made for an organization, not an individual user.
            Before we can set you up with an API key and start making requests, you
            need to create an organization.
          </div>

          <FormikGeneralErrors className='mb-2' />

          <FormikTextField className='mb-6' name='organizationName' placeholder='Acme Corporation' />

          <div className='flex justify-end'>
            <div>
              <FormikButton className='w-full mb-4'>Create Organization</FormikButton>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
}

export default OnboardingModal;
