import _ from 'lodash';

import Plus from '@heroicons/react/solid/PlusIcon';
import Button from '../../components/atoms/Button';
import DetailRow from '../../components/atoms/DetailRow';
import DetailLabel from '../../components/atoms/DetailLabel';
import ApiTokenRow from './ApiTokenRow';

import { CurrentUserFragmentType } from '../../graphql/fragments/current-user';
import { ApiTokenType } from '../../models/api-token';

interface AccountSection1Props {
  currentUser: CurrentUserFragmentType;
  apiTokens: ApiTokenType[];
  onGenerateApiToken: () => void;
}

function AccountSection1({ currentUser, apiTokens, onGenerateApiToken }: AccountSection1Props) {
  const { name } = currentUser.organization;

  return (
    <div className='mb-6 md:mb-0'>
      <h3 className='text-xl font-medium mb-2'>User</h3>

      <div>
        <DetailRow className='pb-2' title='Email' value={currentUser.email} />
      </div>


      <h3 className='text-xl font-medium mt-4 mb-2'>Organization</h3>

      <div>
        <DetailRow className='pb-2' title='Name' value={name} />

        <DetailLabel className='pb-2' title='API Tokens'>
          <>
            {_.map(apiTokens, (apiTokenPayload) => (
              <ApiTokenRow
                key={apiTokenPayload.key}
                className='p-1'
                apiToken={apiTokenPayload}
              />
            ))}

            <Button
              className='flex items-center text-blue-500 p-1'
              variant='text'
              onClick={onGenerateApiToken}
             >
              <Plus className='pr-1 h-5' />
              Generate API Token
            </Button>
          </>
        </DetailLabel>
      </div>
    </div>
  );
}

export default AccountSection1;
