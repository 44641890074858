import { useAuth } from '../../contexts/Auth';

import Button from '../../components/atoms/Button';
import PageHeader from '../../components/atoms/PageHeader';

function AccountHeader() {
  const { logout } = useAuth();

  return (
    <div className='mb-4 flex justify-between'>
      <PageHeader title='Account Information' />

      <Button className='mr-4 md:mr-0' variant='text' onClick={() => logout()}>
        Log Out
      </Button>
    </div>
  );
}

export default AccountHeader;
