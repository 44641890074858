import { ReactNode } from 'react';
import { Dialog } from '@headlessui/react'
import X from '@heroicons/react/solid/XIcon';

import Button from '../Button';

export interface ModalHeaderProps {
  title: ReactNode | string;
  onClose: (() => void) | null;
}

const ModalHeader = ({ title, onClose }: ModalHeaderProps) => {
  return (
    <div className='pb-2 flex justify-between items-center'>
      <Dialog.Title
        as='h3'
        className='text-xl font-medium leading-6'
      >
        {title}
      </Dialog.Title>

      {onClose ? (
        <Button variant='icon' onClick={onClose}>
          <X className='h-6' />
        </Button>
      ) : null}
    </div>
  );
};

export default ModalHeader;
