import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import cx from 'classnames';
import _ from 'lodash';

import SelectorIcon from '@heroicons/react/solid/SelectorIcon'
import CheckIcon from '@heroicons/react/solid/CheckIcon'

export interface SelectOption {
  label: string;
  value: string;
}

export interface SelectProps {
  className?: string;
  options: (SelectOption | null)[];
  selectedOption: SelectOption | null;
  onChange: (value: SelectOption | null) => void;
  placeholder?: string;
  isErrored?: boolean;
}

function Select({
  className,
  options,
  selectedOption,
  onChange,
  placeholder = 'Select an option',
  isErrored = false,
}: SelectProps) {
  const selectedValue = selectedOption?.value || null;

  return (
    <div className={cx('relative', className)}>
      <Listbox
        value={selectedValue}
        onChange={(value) => {
          const newSelectedOption = value
            ? (_.find(options, { value }) || null)
            : null;

          onChange(newSelectedOption);
        }}
      >
        <Listbox.Button
          className={cx(
            'relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg',
            'cursor-default sm:text-sm focus:outline-none border',
            'focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-primary',
            'focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:border-primary',
            {
              'border-gray-300': !isErrored,
              'border-red-300': isErrored,
            }
          )}
        >
          <span className='block truncate'>
            {selectedOption ? (
              selectedOption.label
            ) : (
              <span className='text-gray-400'>{placeholder}</span>
            )}
          </span>

          <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
            <SelectorIcon
              className='w-5 h-5 text-gray-400'
              aria-hidden='true'
            />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Listbox.Options className='absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {options.map((option) => {
              const optionValue = option?.value || null;

              return (
                <Listbox.Option
                  key={optionValue || 'null'}
                  className={({ active }) =>
                    `cursor-default select-none relative py-2 pl-10 pr-4 ${
                      active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'
                    }`
                  }
                  value={optionValue}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={cx('block truncate', {
                          'font-medium': selected,
                          'font-normal': !selected,
                        })}
                      >
                        {option ? (
                          option.label
                        ) : (
                          placeholder
                        )}
                      </span>

                      {selected ? (
                        <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600'>
                          <CheckIcon className='w-5 h-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
}

export default Select;
