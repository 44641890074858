import NavLink from '../../../components/atoms/NavLink';
import LogoName from '../../_partials/LogoName';
import LogoIcon from '../../_partials/LogoIcon';

import { TOPNAV_HEIGHT } from './constants';

const LINK_CNAME = 'p-4 md:p-6 hover:bg-gray-200 flex items-center';
const ACTIVE_CNAME = 'bg-gray-100';

const TopNav = () => {
  return (
    <nav className='w-full flex justify-between shadow border-b' style={{ height: TOPNAV_HEIGHT }}>
      <div className='px-4 py-1 flex'>
        <LogoName style={{ width: 100 }} className='hidden sm:block' />
        <LogoIcon style={{ width: 30 }} className='sm:hidden' />
      </div>

      <div className='flex'>
        <NavLink to='/' className={LINK_CNAME} activeClassName={ACTIVE_CNAME}>
          Dashboard
        </NavLink>
        <NavLink to='/invocations' className={LINK_CNAME} activeClassName={ACTIVE_CNAME}>
          History
        </NavLink>
        <NavLink to='/account' className={LINK_CNAME} activeClassName={ACTIVE_CNAME}>
          Account
        </NavLink>
      </div>
    </nav>
  );
};

export default TopNav;
