import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import Card from '../../../components/atoms/Card';
import LogoName from '../../_partials/LogoName';

interface AuthLayoutProps {
  children?: ReactElement;
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <div className='min-h-screen bg-gray-100 flex flex-col items-center justify-center'>
      <LogoName style={{ maxWidth: 300 }} className='mb-8 hidden sm:block' />

      <Card className='w-full sm:max-w-md p-4 sm:p-6 min-h-screen sm:min-h-0'>
        <div className='flex justify-center mb-12 sm:hidden'>
          <LogoName style={{ maxWidth: 240 }} />
        </div>

        {children || <Outlet />}
      </Card>
    </div>
  );
};

export default AuthLayout;
