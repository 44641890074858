import format from 'date-fns/format';

import DetailRow from '../../../components/atoms/DetailRow';
import InvocationStatus from '../InvocationStatus';

import { InvocationType } from '../../../models/invocation';

interface MobileInvocationsTableProps {
  invocations: InvocationType[];
}

function MobileInvocationsTable({ invocations }: MobileInvocationsTableProps) {
  return (
    <>
      {invocations.map((invocation) => {
        return (
          <div key={invocation.id} className='p-3'>
            <div className='flex'>
              <InvocationStatus status={invocation.status} className='mr-2' />

              <span>
                {format(new Date(invocation.createdAt), 'MMM d, pp')}
              </span>
            </div>

            <DetailRow title='Foreign Identifier' value={invocation.foreignIdentifier} />
            <DetailRow title='Payload' value={invocation.requestPayload} />
          </div>
        );
      })}
    </>
  );
}

export default MobileInvocationsTable;
