import { ReactNode } from 'react';

export interface DetailLabelProps {
  children: ReactNode;
  className?: string;
  title: string;
}

const DetailLabel = ({ className, title, children }: DetailLabelProps) => {
  return (
    <div className={className}>
      <div className='font-semibold'>{title}:</div>
      <div>
        {children}
      </div>
    </div>
  );
};

export default DetailLabel;
