import { CSSProperties } from 'react';
import cx from 'classnames';
import { ComponentPropsWithoutRef } from 'react';

import LoadingSpinner from '../LoadingSpinner';

export interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  className?: string;
  variant?: 'primary' | 'secondary' | 'text' | 'icon';
  isLoading?: boolean;
}

const Button = ({
  children,
  className,
  variant = 'primary',
  type = 'button',
  isLoading = false,
  disabled = false,
  ...rest
}: ButtonProps) => {
  const isPrimary = variant === 'primary';
  const isSecondary = variant === 'secondary';
  const isText = variant === 'text';
  const isIcon = variant === 'icon';

  const spinnerColor = isPrimary ? '#FFFFFF' : '#43484D';
  const spinnerBackgroundCName = cx({
    'bg-primary bg-opacity-50': isPrimary,
    'bg-primary bg-opacity-10': isSecondary,
    '': isText,
    'bg-slate-900 bg-opacity-20': isIcon,
  });


  const buttonBackgroundCName = cx({
    'bg-primary bg-opacity-90': isPrimary,
    'bg-primary bg-opacity-0': isSecondary,
    '': isText,
    'bg-slate-900 bg-opacity-0': isIcon,
  });
  const buttonBackgroundHoverCName = cx({
    'hover:bg-opacity-100': isPrimary && !disabled,
    'hover:bg-opacity-10': isSecondary && !disabled,
    '': isText,
    'hover:bg-opacity-20': isIcon && !disabled,
  });

  const buttonCName = cx(
    className,
    'relative focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
    'rounded-md overflow-hidden',
    buttonBackgroundCName,
    isLoading ? '' : buttonBackgroundHoverCName,
    {
      'px-4 flex justify-center items-center text-sm font-medium text-white': isPrimary,
      'px-4 flex justify-center items-center text-sm font-medium border border-2 border-primary': isSecondary,
      '': isText,
      'p-2': isIcon,
    }
  );

  const buttonStyle: CSSProperties = {};
  if (isPrimary || isSecondary || isText) buttonStyle.height = 38;

  return (
    <button
      type={type}
      className={buttonCName}
      style={buttonStyle}
      disabled={isLoading || disabled}
      {...rest}
    >
      {isLoading && (
        <div
          className={cx(
            'absolute inset-0 flex justify-center items-center',
            spinnerBackgroundCName
          )}
        >
          <LoadingSpinner size={24} color={spinnerColor} />
        </div>
      )}

      {children}
    </button>
  );
}

export default Button;
