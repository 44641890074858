import { Link } from 'react-router-dom';

// TODO: Proper docs link

function DashboardEmptyState() {
  return (
    <div className='p-8 flex justify-center'>
      <div className='max-w-lg'>
        It looks like you haven't generated any documents yet. If you need to
        set up an API Token for you first request you can
        <Link className='text-blue-500' to='/account'> do that from the accounts page</Link>.
        Or if you need help triggering a request you can
        <a className='text-blue-500' href='https://docs.scrivenr.io'> read through the documentation</a>.
      </div>
    </div>
  );
}

export default DashboardEmptyState;
