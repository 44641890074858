import { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import { useQuery, gql, NetworkStatus } from '@apollo/client';

import { cache } from '../../../vendor/apollo-client';
import {
  invocationRowFragment,
  InvocationRowFragmentType,
} from '../../../graphql/fragments/invocation-row';
import useErrorToastHandling from '../../../hooks/use-error-toast-handling';

import Modal, { ModalHeader, ModalBody } from '../../../components/atoms/Modal';
import DetailRow from '../../../components/atoms/DetailRow';
import DetailLabel from '../../../components/atoms/DetailLabel';
import PageSpinner from '../../../components/atoms/PageSpinner';
import InvocationStatus from '../../_partials/InvocationStatus';

const GET_INVOCATION = gql`
  ${invocationRowFragment}

  query GetInvocation($id: String!) {
    invocation(id: $id) {
      ...invocationRowFragment
    }
  }
`;

const InvocationsShow = () => {
  const { invocationId } = useParams();

  const navigate = useNavigate();
  const handleModalClose = useCallback(() => navigate('/invocations'), [navigate]);

  const cachedInvocation = cache.readFragment<InvocationRowFragmentType>({
    id: `Invocation:${invocationId}`,
    fragment: invocationRowFragment,
  });

  const { data, networkStatus, error } = useQuery(GET_INVOCATION, {
    skip: !!cachedInvocation,
    variables: { id: invocationId }
  });

  const invocation = cachedInvocation || data?.invocation;

  useErrorToastHandling(
    'We were unable to retrieve this invocation. We have been alerted and will find a fix soon.',
    error
  );

  return (
    <Modal
      isOpen
      onClose={handleModalClose}
    >
      <ModalHeader
        title={(
          <div className='flex items-center'>
            <InvocationStatus className='mr-2' status={invocation?.status} />

            Invocation Detail
          </div>
        )}
        onClose={handleModalClose}
      />

      <ModalBody>
        {networkStatus === NetworkStatus.loading ? (
          <PageSpinner />
        ) : !invocation ? (
          <div className='flex justify-center items-center'>
            We were unable to locate this record. Please try reloading the page.
          </div>
        ) : (
          <>
            <DetailRow className='pt-3' title='Invoked At' value={format(new Date(invocation.createdAt), 'PPpp')} />
            <DetailRow className='pt-3' title='Type' value={invocation.serviceType} />
            <DetailRow className='pt-3' title='Identifier' value={invocation.foreignIdentifier} />

            <DetailLabel className='pt-3' title='Request Payload'>
              <pre className='p-2 bg-gray-100 text-sm overflow-x-auto'>
                {JSON.stringify(JSON.parse(invocation.requestPayload), null, 2)}
              </pre>
            </DetailLabel>

            <DetailLabel className='pt-3' title='Response Payload'>
              <pre className='p-2 bg-gray-100 text-sm overflow-x-auto'>
                {JSON.stringify(JSON.parse(invocation.responsePayload), null, 2)}
              </pre>
            </DetailLabel>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default InvocationsShow;
