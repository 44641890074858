import { gql } from '@apollo/client';

import { InvocationType } from '../../models/invocation';

export const invocationRowFragment = gql`
  fragment invocationRowFragment on Invocation {
    id
    status
    createdAt
    serviceType
    foreignIdentifier
    requestPayload
    responsePayload
    cost
  }
`;

export interface InvocationRowFragmentType extends InvocationType {}
