import { createContext, useContext, useCallback, ReactNode } from 'react';

import useAuthState, { AuthStateType } from '../../services/authentication/use-auth-state';
import useVerificationListener from '../../services/authentication/use-verification-listener';
import { logout as serviceLogout } from '../../services/authentication';

// import { UserType } from '../../models/user';

export interface AuthContextType {
  isAuthenticated: boolean;
  authState: AuthStateType | null;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: ReactNode }) {
  const [authState, setAuthState] = useAuthState();
  const isAuthenticated = Boolean(authState);

  useVerificationListener({ isAuthenticated });

  const logout = useCallback(() => {
    return serviceLogout().then(() => {
      setAuthState(null);
    });
  }, [setAuthState]);

  const value = {
    isAuthenticated,
    authState,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
   );
}

function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };