import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { offsetLimitPagination } from "@apollo/client/utilities";

import { getAuthToken } from '../services/authentication';

const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL;

const httpLink = createHttpLink({ uri: GRAPHQL_URL });

const authLink = setContext((_, { headers }) => {
  return getAuthToken().then((token) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  }));
});

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        invocations: offsetLimitPagination(['filters']),
      },
    },
  },
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

export default client;
