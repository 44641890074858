import { createContext, useContext, ReactNode } from 'react';
import { useQuery, gql, NetworkStatus } from '@apollo/client';

import useErrorToastHandling from '../../hooks/use-error-toast-handling';

import { currentUserFragment, CurrentUserFragmentType } from '../../graphql/fragments/current-user';

import OnboardingModal from './OnboardingModal';

const GET_CURRENT_USER = gql`
  ${currentUserFragment}

  query GetCurrentUser {
    currentUser {
      ...currentUserFragment
    }
  }
`;

interface GetCurrentUserData {
  currentUser: CurrentUserFragmentType | null;
}

interface SessionContextType {
  currentUser: CurrentUserFragmentType | null;
}

const SessionContext = createContext<SessionContextType>(null!);

function SessionProvider({ children }: { children: ReactNode }) {
  const { data, networkStatus, refetch, error } = useQuery<GetCurrentUserData>(GET_CURRENT_USER);
  useErrorToastHandling(
    'There was an error retrieving your session information from our server. Please contact support and we will get it sorted out.',
    error
  );

  const currentUser = data?.currentUser || null;
  const hasCompletedFirstFetch = networkStatus !== NetworkStatus.loading;

  return (
    <SessionContext.Provider
      value={{
        currentUser
      }}
    >
      {hasCompletedFirstFetch ? children : null}

      <OnboardingModal
        isOpen={hasCompletedFirstFetch && !currentUser && !error}
        onSuccess={() => refetch()}
      />
    </SessionContext.Provider>
  );
}

function useSession() {
  return useContext(SessionContext);
}

export { SessionProvider, useSession, GET_CURRENT_USER };
