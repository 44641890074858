import { useField } from 'formik';
import _ from 'lodash';

import FormLabel from '../../atoms/FormLabel';
import Select, { SelectProps, SelectOption } from '../../atoms/Select';

interface FormikSelectProps extends Omit<SelectProps, 'selectedOption' | 'onChange'> {
  name: string;
  label?: string;
  className?: string;
  onChange?: (newOption: SelectOption | null) => void;
}

export default function FormikSelect({
  name,
  label,
  className,
  options,
  onChange,
  ...rest
}: FormikSelectProps) {
  const [field, meta, helpers] = useField(name);

  const error = meta.touched && meta.error ? meta.error : null;

  const selectedOption = _.find(options, { value: field.value }) || null;

  return (
    <FormLabel
      name={name}
      label={label}
      className={className}
      error={error}
    >
      <Select
        {...field}
        {...rest}
        isErrored={!!error}
        options={options}
        selectedOption={selectedOption}
        onChange={(newOption) => {
          helpers.setValue(newOption?.value || null);
          if (onChange) onChange(newOption);
        }}
      />
    </FormLabel>
  );
}
