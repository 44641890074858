import { CSSProperties } from 'react';

interface LogoIconProps {
  className?: string;
  style?: CSSProperties;
}

function LogoIcon({ style, className }: LogoIconProps) {
  return (
    <svg
      viewBox="0 0 160 202"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path d="M26.6667 197H133.333C138.991 197 144.417 194.752 148.418 190.752C152.419 186.751 154.667 181.325 154.667 175.667V73.416C154.666 70.5873 153.542 67.8746 151.541 65.8747L93.792 8.12533C91.7921 6.12481 89.0794 5.0006 86.2507 5H26.6667C21.0087 5 15.5825 7.24761 11.5817 11.2484C7.58094 15.2492 5.33333 20.6754 5.33333 26.3333V175.667C5.33333 181.325 7.58094 186.751 11.5817 190.752C15.5825 194.752 21.0087 197 26.6667 197Z" stroke="#616AE8" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M97.5909 81.6818C97.2273 78.0151 95.6667 75.1667 92.9091 73.1364C90.1515 71.1061 86.4091 70.0909 81.6818 70.0909C78.4697 70.0909 75.7576 70.5455 73.5455 71.4545C71.3333 72.3333 69.6364 73.5606 68.4545 75.1364C67.303 76.7121 66.7273 78.5 66.7273 80.5C66.6667 82.1667 67.0152 83.6212 67.7727 84.8636C68.5606 86.1061 69.6364 87.1818 71 88.0909C72.3636 88.9697 73.9394 89.7424 75.7273 90.4091C77.5152 91.0455 79.4242 91.5909 81.4545 92.0455L89.8182 94.0455C93.8788 94.9545 97.6061 96.1667 101 97.6818C104.394 99.197 107.333 101.061 109.818 103.273C112.303 105.485 114.227 108.091 115.591 111.091C116.985 114.091 117.697 117.53 117.727 121.409C117.697 127.106 116.242 132.045 113.364 136.227C110.515 140.379 106.394 143.606 101 145.909C95.6364 148.182 89.1667 149.318 81.5909 149.318C74.0758 149.318 67.5303 148.167 61.9545 145.864C56.4091 143.561 52.0758 140.152 48.9545 135.636C45.8636 131.091 44.2424 125.47 44.0909 118.773H63.1364C63.3485 121.894 64.2424 124.5 65.8182 126.591C67.4242 128.652 69.5606 130.212 72.2273 131.273C74.9242 132.303 77.9697 132.818 81.3636 132.818C84.697 132.818 87.5909 132.333 90.0455 131.364C92.5303 130.394 94.4545 129.045 95.8182 127.318C97.1818 125.591 97.8636 123.606 97.8636 121.364C97.8636 119.273 97.2424 117.515 96 116.091C94.7879 114.667 93 113.455 90.6364 112.455C88.303 111.455 85.4394 110.545 82.0455 109.727L71.9091 107.182C64.0606 105.273 57.8636 102.288 53.3182 98.2273C48.7727 94.1667 46.5152 88.697 46.5455 81.8182C46.5152 76.1818 48.0152 71.2576 51.0455 67.0455C54.1061 62.8333 58.303 59.5455 63.6364 57.1818C68.9697 54.8182 75.0303 53.6364 81.8182 53.6364C88.7273 53.6364 94.7576 54.8182 99.9091 57.1818C105.091 59.5455 109.121 62.8333 112 67.0455C114.879 71.2576 116.364 76.1364 116.455 81.6818H97.5909Z" fill="#616AE8"/>
    </svg>
  );
}

export default LogoIcon;
