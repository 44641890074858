import { Helmet } from 'react-helmet';

interface PageHeaderProps {
  className?: string;
  title: string;
  headTitle?: string | null;
}

const PageHeader = ({ className, title, headTitle }: PageHeaderProps) => {
  return (
    <>
      <h1 className={`text-2xl px-4 md:px-0 ${className}`}>{title}</h1>

      {headTitle !== null ? (
        <Helmet>
          <title>{`Scrivenr - ${headTitle || title}`}</title>
        </Helmet>
      ) : null}
    </>
  );
};

export default PageHeader;
