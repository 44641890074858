import Button from '../../../components/atoms/Button';
import NoUsageEmptyState from '../../_partials/NoUsageEmptyState';

// TODO: Proper docs link

interface InvocationsIndexEmptyStateProps {
  isFiltered: boolean;
  onResetFilters: () => void;
}

function InvocationsIndexEmptyState({
  isFiltered,
  onResetFilters,
}: InvocationsIndexEmptyStateProps) {
  return (
    isFiltered ? (
      <div className='p-8 flex flex-col items-center'>
        <div className='mb-4'>
          No results were found for this set of filters.
          You can reset the filters by clicking below.
        </div>

        <Button onClick={onResetFilters}>Reset Filters</Button>
      </div>
    ) : (
      <NoUsageEmptyState />
    )
  );
}

export default InvocationsIndexEmptyState;
