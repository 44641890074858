import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import * as Sentry from '@sentry/browser';

import auth from './auth';

import { identifyUser } from '../../helpers/tracking';
import useLocalStorage from '../../hooks/use-local-storage';

export interface AuthStateType {
  uid: string;
  email: string | null;
}

type NullableAuthStateType = AuthStateType | null;

export default function useAuthState(): [NullableAuthStateType, (value: NullableAuthStateType) => void] {
  const [authState, setAuthState] = useLocalStorage<NullableAuthStateType>('auth-state', null);

  useEffect(() => {
    const cleanupHandler = onAuthStateChanged(
      auth,
      async (user) => {
        if (user) {
          setAuthState({
            uid: user.uid,
            email: user.email,
          });
          identifyUser({ firebaseId: user.uid, email: user.email });
        }
      },
      (e) => {
        Sentry.captureException(e);
      }
    );

    return () => {
      cleanupHandler();
    };
  }, [setAuthState]);

  return [authState, setAuthState];
}
