import { ReactNode } from 'react';

export interface ModalBodyProps {
  children: ReactNode;
}

const ModalBody = ({ children }: ModalBodyProps) => {
  return (
    <div className=''>
      {children}
    </div>
  );
};

export default ModalBody;
