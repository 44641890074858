import { initializeApp } from 'firebase/app';
// TODO: Add analytics; import { getAnalytics } from 'firebase/analytics';

enum DeployEnvironments {
  Development = 'development',
  Staging = 'staging',
  Production = 'production'
}

// TODO: Local firebase emulator?
const deployEnv: DeployEnvironments =
  (process.env.REACT_APP_DEPLY_ENV as DeployEnvironments | undefined) ||
  DeployEnvironments.Development;

const FIREBASE_ENV_CONFIG = {
  development: {
    apiKey: 'AIzaSyAby3rc8JKtYdup4MLIkKyGO-BEP4kOnt4',
    authDomain: 'scrivener-220b1.firebaseapp.com',
    projectId: 'scrivener-220b1',
    storageBucket: 'scrivener-220b1.appspot.com',
    messagingSenderId: '73635311789',
    appId: '1:73635311789:web:2202e704ac909f1c3dd624',
    measurementId: 'G-GPWBFJK1W8'
  },
  staging: {
    apiKey: "AIzaSyCMJy309RUlR97oiTTjjhpzanqPZoJCz0g",
    authDomain: "scrivenr-staging.firebaseapp.com",
    projectId: "scrivenr-staging",
    storageBucket: "scrivenr-staging.appspot.com",
    messagingSenderId: "255917003108",
    appId: "1:255917003108:web:63d2d2096453b1c2a7ea71"
  },
  production: {
    apiKey: "AIzaSyAEhuMV8kpA8BcLTOPuFHcYRSXqgdluM_s",
    authDomain: "scrivenr-production.firebaseapp.com",
    projectId: "scrivenr-production",
    storageBucket: "scrivenr-production.appspot.com",
    messagingSenderId: "538619488463",
    appId: "1:538619488463:web:57ecc36a78f0ebacadafba",
    measurementId: "G-F4GVQWQNKZ"
  },
}

const firebaseConfig = FIREBASE_ENV_CONFIG[deployEnv];

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
