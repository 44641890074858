import { Link, useResolvedPath, useMatch, LinkProps } from 'react-router-dom';
import cx from 'classnames';

interface NavLinkProps extends LinkProps {
  to: string;
  className?: string;
  activeClassName?: string;
}

const NavLink = ({ to, className, activeClassName = '', ...rest }: NavLinkProps) => {
  const resolved = useResolvedPath(to);
  const isMatch = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      to={to}
      className={cx(className, { [activeClassName]: isMatch })}
      {...rest}
    />
  );
};

export default NavLink;
