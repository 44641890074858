import { Outlet } from 'react-router-dom';

import { TOPNAV_HEIGHT } from './constants';

import TopNav from './TopNav';

const GeneralLayout = () => {
  return (
    <div>
      <TopNav />

      <div className='bg-gray-100 py-4 md:p-6' style={{ minHeight: `calc(100vh - ${TOPNAV_HEIGHT}px)` }}>
        <Outlet />
      </div>
    </div>
  );
};

export default GeneralLayout;
