import { Routes, Route, Navigate } from 'react-router-dom';

import GeneralLayout from './_layouts/GeneralLayout';
import AuthLayout from './_layouts/AuthLayout';

import AuthenticatedRoute from './_wrappers/AuthenticatedRoute';
import UnauthenticatedRoute from './_wrappers/UnauthenticatedRoute';

import { SessionProvider } from '../contexts/Session';

import Login from './auth/Login';
import Register from './auth/Register';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import AuthAction from './auth/Action';

import Dashboard from './Dashboard';
import InvocationsIndex from './invocations/InvocationsIndex';
import InvocationsShow from './invocations/InvocationsShow';
import Account from './Account';

import NoMatch from './NoMatch';

import { usePageView } from '../helpers/tracking';;

const Root = () => {
  usePageView();

  return (
    <Routes>
      <Route
        path='/auth'
        element={(
          <UnauthenticatedRoute>
            <AuthLayout />
          </UnauthenticatedRoute>
        )}
      >
        <Route index element={<Navigate to='login' replace />} />
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='reset-password' element={<ResetPassword />} />
      </Route>

      <Route path='/auth/action' element={<AuthAction />} />

      <Route
        path='/'
        element={(
          <AuthenticatedRoute>
            <SessionProvider>
              <GeneralLayout />
            </SessionProvider>
          </AuthenticatedRoute>
        )}
      >
        <Route index element={<Dashboard />} />
        <Route path='account' element={<Account />} />
        <Route path='invocations' element={<InvocationsIndex />}>
          <Route path=':invocationId' element={<InvocationsShow />} />
        </Route>
      </Route>

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default Root;
