import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import '../types/window';

interface IdentifyUserParams {
  firebaseId: string;
  email?: string | null;
}

const isSegmentEnabled = (): boolean => {
  return (
    process.env.REACT_APP_TRACKING_ENABLED === 'true' &&
    window.analytics &&
    !window.Cypress
  );
};

export const identifyUser = (
  params: IdentifyUserParams,
  authorizedTraits?: Object,
) => {
  const { firebaseId, email } = params;

  if (isSegmentEnabled()) {
    window.analytics.identify(firebaseId, { email, ...(authorizedTraits || {}) });
  }
};

export const trackEvent = (eventName: string, eventData?: Object) => {
  if (isSegmentEnabled()) {
    window.analytics.track(eventName, eventData);
  }
};

export function usePageView() {
  const location = useLocation();

  useEffect(() => {
    window.analytics.page(undefined, undefined, { path: location.pathname });
  }, [location]);
}
