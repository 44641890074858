import CheckCircle from '@heroicons/react/outline/CheckCircleIcon';
import DetailRow from '../../../components/atoms/DetailRow';

import { ApiTokenType } from '../../../models/api-token';

interface ApiTokenDisplayProps {
  apiToken: ApiTokenType;
  apiSecret: string;
  onClose: () => void;
}

function ApiTokenDisplay({
  apiToken,
  apiSecret,
  onClose,
}: ApiTokenDisplayProps) {
  return (
    <>
      <div className='mb-6 flex items-center'>
        <CheckCircle className='h-12 text-success mr-2' />

        <div className='text-sm'>
          The API token and secret were created successfully. Make sure to
          copy the secret to a secure location now, as we will not be able
          to show it again.
        </div>
      </div>

      <DetailRow title='Description' value={apiToken.description} />
      <DetailRow title='API Token' value={apiToken.key} />
      <DetailRow title='API Secret' value={apiSecret} />
    </>
  );
}

export default ApiTokenDisplay;
