import * as React from "react";
import { ApolloProvider } from '@apollo/client';

import apolloClient from './vendor/apollo-client';

import Root from './routes/Root';
import ToastContainer from './vendor/react-toastify/ToastContainer';

import { AuthProvider } from './contexts/Auth';

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <Root />

        <ToastContainer />
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
