import { Form, useFormikContext } from 'formik';

import Button from '../../../components/atoms/Button';
import FormikTextField from '../../../components/formik/FormikTextField';
import FormikSelect from '../../../components/formik/FormikSelect';

// TODO: Datetime search, freetext search for foreign identifier and payload, select dropdown for type

const STATUS_OPTIONS = [
  null,
  { label: 'Success', value: 'success' },
  { label: 'Failure', value: 'failure' }
];

interface InvocationsFiltersProps {
  onResetFilters: () => void;
}

const InvocationsFilters = ({ onResetFilters }: InvocationsFiltersProps) => {
  const { submitForm } = useFormikContext();

  return (
    <Form>
      <div className='flex flex-col md:flex-row justify-between items-end'>
        <div className='mb-2 md:mb-0 w-full grid grid-cols-2 sm:grid-cols-3'>
          <FormikTextField
            className='mr-2'
            name='foreignIdentifier'
            label='Identifier'
            placeholder='3030'
          />
          <FormikTextField
            className='mr-2'
            name='search'
            label='Search Payload'
          />
          <FormikSelect
            className='mr-2'
            name='status'
            options={STATUS_OPTIONS}
            onChange={submitForm}
          />
        </div>

        <div className='flex items-end'>
          <Button className='mr-2' variant='secondary' onClick={onResetFilters}>
            Reset
          </Button>

          <Button type='submit'>Filter</Button>
        </div>
      </div>
    </Form>
  );
};

export default InvocationsFilters;
