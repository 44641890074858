import { useState, useCallback } from 'react';

import Modal, { ModalHeader, ModalProps } from '../../../components/atoms/Modal';
import ApiTokenForm from './ApiTokenForm';
import ApiTokenDisplay from './ApiTokenDisplay';

import { ApiTokenType } from '../../../models/api-token';

interface ApiTokenDetailsState {
  apiToken: ApiTokenType;
  apiSecret: string;
}

interface GenerateApiTokenModalProps extends Pick<ModalProps, 'isOpen' | 'onClose'> {}

const GenerateApiTokenModal = ({ isOpen, onClose }: GenerateApiTokenModalProps) => {
  const [apiTokenDetails, setApiTokenDetails] = useState<ApiTokenDetailsState | null>(null);

  const handleClose = useCallback(() => {
    onClose();
    setTimeout(() => {
      setApiTokenDetails(null);
    }, 200);
  }, [onClose, setApiTokenDetails]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalHeader title='Generage API Token' onClose={handleClose} />

      {apiTokenDetails ? (
        <ApiTokenDisplay
          apiToken={apiTokenDetails.apiToken}
          apiSecret={apiTokenDetails.apiSecret}
          onClose={handleClose}
        />
      ) : (
        <ApiTokenForm
          onClose={handleClose}
          onSuccess={(apiToken: ApiTokenType, apiSecret: string) => {
            setApiTokenDetails({ apiToken, apiSecret });
          }}
        />
      )}
    </Modal>
  );
};

export default GenerateApiTokenModal
