import cx from 'classnames';

export interface DetailRowProps {
  className?: string;
  title: string;
  value: string;
}

const DetailRow = ({ className, title, value }: DetailRowProps) => (
  <div className={cx('flex', className)}>
    <span className='font-semibold pr-1'>{title}:</span>
    <span className='truncate'>{value}</span>
  </div>
);

export default DetailRow;
