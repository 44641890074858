import cx from 'classnames';

import CheckCircleIcon from '@heroicons/react/solid/CheckCircleIcon';
import ExclamationCircleIcon from '@heroicons/react/outline/ExclamationCircleIcon';

interface InvocationStatusProps {
  className?: string;
  status?: string;
}

function InvocationStatus({ className, status }: InvocationStatusProps) {
  if (!status) return null;

  return (
    status === 'success' ? (
      <CheckCircleIcon className={cx(className, 'text-success h-6')} />
    ) : (
      <ExclamationCircleIcon className={cx(className, 'text-error h-6')} />
    )
  );
}

export default InvocationStatus;
