import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import * as Sentry from "@sentry/browser";

import {
  EMAIL_VALIDATION,
  PASSWORD_VALIDATION,
  PASSWORD_CONFIRMATION_VALIDATION,
} from '../../../helpers/validations';
import { registerUser } from '../../../services/authentication';

import FormikTextField from '../../../components/formik/FormikTextField';
import FormikGeneralErrors from '../../../components/formik/FormikGeneralErrors';
import FormikButton from '../../../components/formik/FormikButton';
import GoogleSignInButton from '../_partials/GoogleSignInButton';

// TODO: Make Password Text Field with eye on/off

const VALIDATION_SCHEMA = yup.object().shape({
  email: EMAIL_VALIDATION,
  password: PASSWORD_VALIDATION,
  passwordConfirmation: PASSWORD_CONFIRMATION_VALIDATION,
});

const Register = () => {
  return (
    <div className='flex flex-col items center'>
      <GoogleSignInButton>
        Sign Up With Google
      </GoogleSignInButton>

      <div className='mb-6 flex items-center'>
        <hr className='w-full' />
        <span className='text-xs font-medium px-3'>OR</span>
        <hr className='w-full' />
      </div>

      <Formik
        initialValues={{ email: '', password: '', passwordConfirmation: '' }}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          registerUser({ email: values.email, password: values.password })
          .catch((e) => {
            setSubmitting(false);

            if (e?.code === 'auth/email-already-in-use') {
              setFieldError('general', 'Email is already in use');
            } else {
              setFieldError('general', 'Something went wrong, please contact support');
              Sentry.captureException(e?.code || 'auth-error', { extra: e });
            }
          });
        }}
      >
        <Form className='mb-6'>
          <FormikGeneralErrors className='mb-2' />

          <FormikTextField className='mb-4' name='email' type='email' />
          <FormikTextField className='mb-4' name='password' type='password' />
          <FormikTextField className='mb-8' name='passwordConfirmation' type='password' />

          <FormikButton className='w-full mb-4'>Get Started for Free</FormikButton>
        </Form>
      </Formik>

      <div className='flex justify-center'>
        Already have an account?
        <Link to='/auth/login' className='pl-1 text-blue-500'>Log In</Link>
      </div>
    </div>
  );
};

export default Register;
