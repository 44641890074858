import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  sendPasswordResetEmail,
  confirmPasswordReset,
  signOut,
  getIdToken,
  GoogleAuthProvider,
  User,
  UserCredential,
} from 'firebase/auth';
import * as Sentry from '@sentry/browser';

import auth from './auth';

const googleProvider = new GoogleAuthProvider();

interface RegisterUserParams {
  email: string;
  password: string;
}

interface PasswordSignInParams {
  email: string;
  password: string;
}

interface ForgotPasswordParams {
  email: string;
}

interface ResetPasswordParams {
  code: string;
  password: string;
}

interface GoogleSignInParams {
  isMobileOrTablet: boolean;
}

export function registerUser(params: RegisterUserParams): Promise<UserCredential> {
  const { email, password } = params;

  return createUserWithEmailAndPassword(auth, email, password).then((userCredentials) => {
    return sendEmailVerification(userCredentials.user).then(() => userCredentials);
  });
}

export function signInUserWithPassword(params: PasswordSignInParams): Promise<UserCredential> {
  const { email, password } = params;

  return signInWithEmailAndPassword(auth, email, password);
}

export function forgotPasswordRequest(params: ForgotPasswordParams): Promise<void> {
  return sendPasswordResetEmail(auth, params.email, { url: 'https://scrivenr.io/test' });
}

export function resetUserPassword(params: ResetPasswordParams): Promise<void> {
  return confirmPasswordReset(auth, params.code, params.password);
}

export function signInUserWithGoogle({ isMobileOrTablet }: GoogleSignInParams): Promise<UserCredential> {
  return isMobileOrTablet
    ? signInWithRedirect(auth, googleProvider)
    : signInWithPopup(auth, googleProvider);
}

export function signInUserWithFacebook(): Promise<void> {
  return Promise.resolve(undefined);
}

export function logout(): Promise<void> {
  return signOut(auth);
}

function getCurrentUser(): Promise<User | null> {
  return new Promise((resolve) => {
    let attempts = 0;

    const intervalRef = setInterval(() => {
      if (auth.currentUser) {
        clearInterval(intervalRef);
        resolve(auth.currentUser);
      } else {
        attempts++;
      }

      if (attempts > 40) {
        clearInterval(intervalRef)
        resolve(null);
      }
    }, 50);
  });
}

function getTokenForUser(user: User): Promise<string | null> {
  return getIdToken(user)
  .catch((e) => {
    Sentry.captureException(e);
    return null;
  });
}

export function getAuthToken(): Promise<string | null> {
  return getCurrentUser().then((currentUser) => {
    return currentUser
      ? getTokenForUser(currentUser)
      : Promise.resolve(null);
  });
}
