import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Formik } from 'formik';

import useErrorToastHandling from '../../../hooks/use-error-toast-handling';

import { InView } from 'react-intersection-observer';
import Card from '../../../components/atoms/Card';
import PageHeader from '../../../components/atoms/PageHeader';
import PageSpinner from '../../../components/atoms/PageSpinner';
import InvocationsTable from '../../_partials/InvocationsTable';
import InvocationsFilters from './InvocationsFilters';
import InvocationsIndexEmptyState from './InvocationsIndexEmptyState';

import { invocationRowFragment, InvocationRowFragmentType } from '../../../graphql/fragments/invocation-row';

const GET_INVOCATIONS_HISTORY = gql`
  ${invocationRowFragment}

  query GetInvocationsHistory($filters: InvocationFiltersInput!, $offset: Int!, $limit: Int!) {
    invocations(filters: $filters, offset: $offset, limit: $limit) {
      ...invocationRowFragment
    }
  }
`;

interface GetInvocationsHistoryData {
  invocations: InvocationRowFragmentType[]
}
// TODO: type variables?

const DEFAULT_FILTER_VALUES = {
  startTime: null,
  endTime: null,
  foreignIdentifier: '',
  search: '',
  serviceType: null,
  status: null,
};

const InvocationsIndex = () => {
  const [filters, setFilters] = useState(DEFAULT_FILTER_VALUES);

  // TODO: skip if no current user
  const { data, loading, fetchMore, error } = useQuery<GetInvocationsHistoryData>(GET_INVOCATIONS_HISTORY, {
    variables: {
      limit: 20,
      offset: 0,
      filters,
    },
  });
  const { invocations = [] } = data || {};
  useErrorToastHandling(
    'We were unable to retrieve your invocations. We have been alerted and will find a fix soon.',
    error
  );

  return (
    <Formik
      initialValues={DEFAULT_FILTER_VALUES}
      onSubmit={(values) => {
        setFilters(values);
      }}
    >
      {({ setValues }) => {
        const handleResetFilters = () => {
          setFilters(DEFAULT_FILTER_VALUES);
          setValues(DEFAULT_FILTER_VALUES);
        };

        return (
          <>
            <PageHeader className='mb-4' title='Usage History' />

            <Card className='p-4 mb-4'>
              <InvocationsFilters onResetFilters={handleResetFilters} />
            </Card>

            <Card className='mb-4'>
              {loading ? (
                <PageSpinner />
              ) : (
                <InvocationsTable
                  invocations={invocations}
                  emptyState={(
                    <InvocationsIndexEmptyState
                      isFiltered={true}
                      onResetFilters={handleResetFilters}
                    />
                  )}
                />
              )}
            </Card>

            <InView
              fallbackInView={false}
              initialInView={true}
              onChange={(inView, entry) => {
                const { time } = entry;

                if (inView && time > 3000) {
                  fetchMore({
                    variables: {
                      offset: invocations.length,
                    },
                  });
                }
              }}
            >
              <div className='h-10' />
            </InView>

            <Outlet />
          </>
        );
      }}
    </Formik>
  );
};

export default InvocationsIndex;
