import { Formik, Form } from 'formik';
import { useMutation, gql } from '@apollo/client';
import * as yup from 'yup';
import * as Sentry from "@sentry/browser";

import { MutationResponse } from '../../../types/graphql';

import FormikTextField from '../../../components/formik/FormikTextField';
import FormikGeneralErrors from '../../../components/formik/FormikGeneralErrors';
import FormikModalFooter from '../../../components/formik/FormikModalFooter';

import { ApiTokenType } from '../../../models/api-token';

import { GET_API_TOKENS } from '../index';

const CREATE_API_TOKEN = gql`
  mutation CreateApiToken($input: CreateApiTokenInput!) {
    createApiToken(data: $input) {
      code
      success
      message
      apiToken {
        id
        key
        description
      }
      apiSecret
    }
  }
`;

interface CreateApiTokenResponse extends MutationResponse {
  apiToken: ApiTokenType;
  apiSecret: string;
}
interface MutationRes {
  createApiToken: CreateApiTokenResponse;
}
interface MutationVars {
  input: {
    description: string;
  }
}

interface ApiTokenFormProps {
  onClose: () => void;
  onSuccess: (apiToken: ApiTokenType, apiSecret: string) => void;
}

function ApiTokenForm({ onClose, onSuccess }: ApiTokenFormProps) {
  const [createApiToken] = useMutation<MutationRes, MutationVars>(CREATE_API_TOKEN, {
    refetchQueries: [GET_API_TOKENS],
  });

  return (
    <Formik
      initialValues={{ description: '' }}
      validationSchema={yup.object().shape({
        description: yup.string().required('Token Description is required'),
      })}
      onSubmit={(input, { setSubmitting, setFieldError }) => {
        createApiToken({ variables: { input } }).then((res) => {
          const { apiToken, apiSecret, message } = res?.data?.createApiToken || {};

          if (!apiToken || !apiSecret) {
            throw new Error(`Missing Api Token/Secret: ${message}`);
          }

          onSuccess(apiToken, apiSecret);
        }).catch((e) => {
          setSubmitting(false);
          setFieldError('general', 'An error occurred - our team is looking into it!');
          Sentry.captureException(e);
        });
      }}
    >
      <Form>
        <FormikGeneralErrors className='mb-2' />

        <div className='mb-6 text-sm'>
          Tokens are identified by a description (provided by you), the token,
          and the token secret. Add a description below and the server will generate
          the token and secret.
        </div>

        <FormikTextField className='mb-6' name='description' placeholder='Accounts Service' />

        <FormikModalFooter
          onCancel={onClose}
          submitText='Create Token'
        />
      </Form>
    </Formik>
  );
}

export default ApiTokenForm;
