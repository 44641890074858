import { useState, useCallback } from 'react';
import _ from 'lodash';
import cx from 'classnames';

import DetailRow from '../../components/atoms/DetailRow';
import DetailLabel from '../../components/atoms/DetailLabel';
import Modal, { ModalHeader } from '../../components/atoms/Modal';

import { ApiTokenType } from '../../models/api-token';

interface ApiTokenRowProps {
  className?: string;
  apiToken: ApiTokenType;
}

const ApiTokenRow = ({ className, apiToken }: ApiTokenRowProps) => {
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const handleModalClose = useCallback(() => {
    setIsDetailOpen(false);
  }, [setIsDetailOpen]);

  return (
    <>
      <div className={cx('flex items-center cursor-pointer hover:bg-gray-100', className)} onClick={() => setIsDetailOpen(true)}>
        {/* TODO: Overflow ellipsis */}
        <span className='pr-2'>{apiToken.description}</span>
        <span className='text-xs font-semibold'>(***{_.slice(apiToken.key, -4)})</span>
      </div>

      <Modal
        isOpen={isDetailOpen}
        onClose={handleModalClose}
      >
        <ModalHeader title='API Key Details' onClose={handleModalClose} />

        <DetailRow className='pt-2' title='API Key' value={apiToken.key} />
        <DetailLabel className='pt-2' title='Description'>{apiToken.description}</DetailLabel>
      </Modal>
    </>
  );
};

export default ApiTokenRow;
