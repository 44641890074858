import { useState, useCallback } from 'react';
import { useQuery, gql } from '@apollo/client';

import { useSession } from '../../contexts/Session';
import useErrorToastHandling from '../../hooks/use-error-toast-handling';

import Card from '../../components/atoms/Card';
import AccountEmptyState from './AccountEmptyState';
import AccountHeader from './AccountHeader';
import AccountSection1 from './AccountSection1';
import GenerateApiTokenModal from './GenerateApiTokenModal';

import { ApiTokenType } from '../../models/api-token';

export const GET_API_TOKENS = gql`
  query GetApiTokens {
    apiTokens {
      id
      key
      description
    }
  }
`;

interface GetApiTokensData {
  apiTokens: ApiTokenType[]
}

const Account = () => {
  const { currentUser } = useSession();

  const { data, error } = useQuery<GetApiTokensData>(GET_API_TOKENS);
  const { apiTokens = [] } = data || {};
  useErrorToastHandling(
    'We were unable to retrieve your API tokens. We have been alerted and will find a fix soon.',
    error
  );

  const [isApiTokenCreateOpen, setIsApiTokenCreateOpen] = useState(false);
  const handleCloseCreateApiToken = useCallback(() => setIsApiTokenCreateOpen(false), [setIsApiTokenCreateOpen]);

  return (
    !currentUser ? (
      <AccountEmptyState />
    ) : (
      <>
        <AccountHeader />

        <Card className='p-4 mb-6 grid grid-cols-1 sm:grid-cols-2'>
          <AccountSection1
            currentUser={currentUser}
            apiTokens={apiTokens}
            onGenerateApiToken={() => setIsApiTokenCreateOpen(true)}
          />

          {/*
          <AccountSection2
            currentUser={currentUser}
          />
          */}
        </Card>

        <GenerateApiTokenModal
          isOpen={isApiTokenCreateOpen}
          onClose={handleCloseCreateApiToken}
        />
      </>
    )
  );
}

export default Account;
